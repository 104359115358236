import { Swiper, SwiperSlide } from 'swiper/react';
import Image from 'next/image';
import { Container } from 'reactstrap';
import { Icon } from '../Icon';
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { Section } from '../Sections';

interface Props {
  src?: string;
  alt?: string;
  width?: number;
  height?: number;
}

export const Slideshow: React.FunctionComponent<Props> = ({
  src,
  alt,
  width,
  height,
}) => {
  const [showIndicator, setShowIndicator] = useState(true);
  const indicatorClass = classNames('slideshow__indicator', {
    ['slideshow__indicator--active']: showIndicator,
  });

  const handleIndicator = () => {
    setShowIndicator(false);
  };

  // Set timeout for making the drag indicator visible
  // again after dragging the slider
  useEffect(() => {
    if (!showIndicator) {
      const indicatorTimeout = setTimeout(() => setShowIndicator(true), 5000);

      return () => {
        clearTimeout(indicatorTimeout);
      };
    }
  }, [showIndicator]);

  return (
    <Section hasBottom={false}>
      <Container fluid>
        {src && (
          <div className="slideshow">
            <Swiper
              className="slideshow"
              slidesPerView={0.2}
              spaceBetween={0}
              lazy={false}
              preloadImages={false}
              freeMode
              onSliderMove={() => handleIndicator()}
              draggable
              grabCursor={true}
              breakpoints={{
                576: {
                  slidesPerView: 0.3,
                },
                992: {
                  slidesPerView: 0.5,
                },
                1240: {
                  slidesPerView: 0.65,
                },
              }}
            >
              <SwiperSlide className="slideshow__slide">
                <Image
                  src={src}
                  alt={alt}
                  width={width}
                  height={height}
                  blurDataURL={src}
                  placeholder="blur"
                />
              </SwiperSlide>
              <div className={indicatorClass}>
                <Icon type="drag-indicator" />
              </div>
            </Swiper>
          </div>
        )}
      </Container>
    </Section>
  );
};
