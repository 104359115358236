import { GetStaticProps, NextPage } from 'next';
import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { Container, Row, Col } from 'reactstrap';
import { RichText } from 'prismic-reactjs';
import { useScroll, motion, useTransform } from 'framer-motion';
import { Layout, SeoMeta } from '../components/Layout';
import { Client } from '../utils';
import { Section } from '../components/Sections';
import { Button } from '../components/Buttons';
import { Slider } from '../components/Slider';

interface Content {
  content: any;
  // gallery: Array<Inner>,
  // imageArray: Array<Image>
}

// interface Inner {
//   map: any;
//   description: Array<Description>,
//   imageArray: Array<Image>
// }

// interface Image {
//   dimensions: {
//     width: number,
//     height: number,
//   },
//   url: string,
//   alt: string,
// }

// interface Description {
//   type: string,
//   text: string
// }

const Home: NextPage<Content> = ({ content }) => {
  const { scrollY, scrollYProgress } = useScroll();
  const [isOnTop, setIsOnTop] = useState<boolean>(true);

  const scaleUpOnScroll = useTransform(scrollYProgress, [0, 1], [1, 1.4]);
  const moveToRight = useTransform(scrollY, [0, 3000], [0, 2000]);
  const moveToLeft = useTransform(scrollY, [0, 3000], [0, -1000]);

  const handleCurtain = () => {
    // @ts-ignore:
    if (scrollY?.current <= 10) {
      setIsOnTop(true);
      // @ts-ignore:
    } else if (scrollY?.current >= 10) {
      setIsOnTop(false);
    }
  };

  // Handle scroll direction
  useEffect(() => {
    const onScroll = () => handleCurtain();

    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  });

  const collageVariants = {
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.8,
      },
    },
    hidden: {
      opacity: 0,
      scale: 0,
      transition: {
        duration: 0.8,
        // delay: 0.3,
        ease: 'easeInOut',
      },
    },
  };

  return (
    <Layout>
      <SeoMeta
        title={RichText.asText(content.seo__title)}
        desc={RichText.asText(content.seo__description)}
        socialImage={content.seo__image}
      />

      <div className="pin-spacer">
        <motion.div className="hero hero--frontpage">
          <div className="hero__inverted-border-radius" />
          <motion.div
            className="hero--frontpage__background"
            style={{
              scale: scaleUpOnScroll,
            }}
          />
          <motion.div
            className="hero__curtain"
            animate={isOnTop ? { translateX: 0 } : { translateX: '-100%' }}
          />
          <div className="collage-wrapper">
            <div className="collage-container collage-container--frontpage">
              <div className="collage collage--frontpage">
                {/*
                  Flower left back
                */}
                <motion.div
                  className="collage__layer collage--frontpage__layer"
                  id="flower-left-back"
                  // style={{
                  //   rotate: rotateToRight
                  // }}
                  animate={isOnTop ? 'visible' : 'hidden'}
                  variants={collageVariants}
                >
                  <Image
                    src={'/collage-landingpage/flower-left-back.png'}
                    alt="a beautiful orange flower"
                    width={450}
                    height={503}
                    layout="responsive"
                    blurDataURL={'/collage-landingpage/flower-left-back.png'}
                    placeholder="blur"
                  />
                </motion.div>
                {/*
                  Surfer right
                */}
                <motion.div
                  className="collage__layer collage--frontpage__layer"
                  id="surfer-right"
                  style={{
                    y: 0,
                    x: moveToLeft,
                  }}
                  animate={isOnTop ? 'visible' : 'hidden'}
                  variants={collageVariants}
                >
                  <Image
                    src={'/collage-landingpage/surfer-right.png'}
                    alt="surfer"
                    width={600}
                    height={305}
                    layout="responsive"
                    blurDataURL={'/collage-landingpage/surfer-right.png'}
                    placeholder="blur"
                  />
                </motion.div>
                {/*
                  Butterfly
                */}
                <motion.div
                  className="collage__layer collage--frontpage__layer"
                  id="butterfly"
                  style={{
                    y: 0,
                    x: moveToLeft,
                  }}
                  animate={isOnTop ? 'visible' : 'hidden'}
                  variants={collageVariants}
                >
                  <Image
                    src={'/collage-landingpage/butterfly.png'}
                    alt="butterfly"
                    width={400}
                    height={373}
                    layout="responsive"
                    blurDataURL={'/collage-landingpage/butterfly.png'}
                    placeholder="blur"
                  />
                </motion.div>
                {/*
                  Plant center back
                */}
                <motion.div
                  className="collage__layer collage--frontpage__layer"
                  id="plant-center-back"
                  animate={isOnTop ? 'visible' : 'hidden'}
                  variants={collageVariants}
                >
                  <Image
                    src={'/collage-landingpage/plant-center-back.png'}
                    alt="plant"
                    width={600}
                    height={932}
                    layout="responsive"
                    blurDataURL={'/collage-landingpage/plant-center-back.png'}
                    placeholder="blur"
                  />
                </motion.div>
                {/*
                  Plant left back
                */}
                <motion.div
                  className="collage__layer collage--frontpage__layer"
                  id="plant-left-back"
                  animate={isOnTop ? 'visible' : 'hidden'}
                  variants={collageVariants}
                >
                  <Image
                    src={'/collage-landingpage/plant-left-back.png'}
                    alt="plant"
                    width={450}
                    height={362}
                    layout="responsive"
                    blurDataURL={'/collage-landingpage/plant-left-back.png'}
                    placeholder="blur"
                  />
                </motion.div>
                {/*
                  Plant right back
                */}
                <motion.div
                  className="collage__layer collage--frontpage__layer"
                  id="plant-right-back"
                  // style={{
                  //   rotate: rotateToLeft
                  // }}
                  animate={isOnTop ? 'visible' : 'hidden'}
                  variants={collageVariants}
                >
                  <Image
                    src={'/collage-landingpage/plant-right-back.png'}
                    alt="plant"
                    width={331}
                    height={450}
                    layout="responsive"
                    blurDataURL={'/collage-landingpage/plant-right-back.png'}
                    placeholder="blur"
                  />
                </motion.div>
                {/*
                  Flowers center front
                */}
                <motion.div
                  className="collage__layer collage--frontpage__layer"
                  id="flowers-center-front"
                  animate={isOnTop ? 'visible' : 'hidden'}
                  variants={collageVariants}
                >
                  <Image
                    src={'/collage-landingpage/flowers-center-front.png'}
                    alt="flowers"
                    width={300}
                    height={499}
                    layout="responsive"
                    blurDataURL={
                      '/collage-landingpage/flowers-center-front.png'
                    }
                    placeholder="blur"
                  />
                </motion.div>
                {/*
                  Flowers white front
                */}
                <motion.div
                  className="collage__layer collage--frontpage__layer"
                  id="flowers-white-front"
                  animate={isOnTop ? 'visible' : 'hidden'}
                  variants={collageVariants}
                >
                  <Image
                    src={'/collage-landingpage/flowers-white-front.png'}
                    alt="flowers"
                    width={300}
                    height={231}
                    layout="responsive"
                    blurDataURL={'/collage-landingpage/flowers-white-front.png'}
                    placeholder="blur"
                  />
                </motion.div>
                {/*
                  Oranges
                */}
                <motion.div
                  className="collage__layer collage--frontpage__layer"
                  id="oranges"
                  animate={isOnTop ? 'visible' : 'hidden'}
                  variants={collageVariants}
                >
                  <Image
                    src={'/collage-landingpage/oranges.png'}
                    alt="oranges"
                    width={400}
                    height={354}
                    layout="responsive"
                    blurDataURL={'/collage-landingpage/oranges.png'}
                    placeholder="blur"
                  />
                </motion.div>
                {/*
                  Girl bikini
                */}
                <motion.div
                  className="collage__layer collage--frontpage__layer"
                  id="girl-bikini"
                  animate={isOnTop ? 'visible' : 'hidden'}
                  variants={collageVariants}
                >
                  <Image
                    src={'/collage-landingpage/girl-bikini.png'}
                    alt="oranges"
                    width={141}
                    height={501}
                    layout="responsive"
                    blurDataURL={'/collage-landingpage/girl-bikini.png'}
                    placeholder="blur"
                  />
                </motion.div>
                {/*
                  Fish left
                */}
                <motion.div
                  className="collage__layer collage--frontpage__layer"
                  id="fish-left"
                  style={{
                    y: 0,
                    x: moveToRight,
                  }}
                  animate={isOnTop ? 'visible' : 'hidden'}
                  variants={collageVariants}
                >
                  <Image
                    src={'/collage-landingpage/fish-left.png'}
                    alt="oranges"
                    width={100}
                    height={33}
                    layout="responsive"
                    blurDataURL={'/collage-landingpage/fish-left.png'}
                    placeholder="blur"
                  />
                </motion.div>
                {/*
                  Seaowl
                */}
                <motion.div
                  className="collage__layer collage--frontpage__layer"
                  id="seaowl"
                  style={{
                    y: 0,
                    x: moveToRight,
                  }}
                  animate={isOnTop ? 'visible' : 'hidden'}
                  variants={collageVariants}
                >
                  <Image
                    src={'/collage-landingpage/seaowl.png'}
                    alt="oranges"
                    width={150}
                    height={139}
                    layout="responsive"
                    blurDataURL={'/collage-landingpage/seaowl.png'}
                    placeholder="blur"
                  />
                </motion.div>
                {/*
                  searoses right front
                */}
                <motion.div
                  className="collage__layer collage--frontpage__layer"
                  id="searoses-right-front"
                  animate={isOnTop ? 'visible' : 'hidden'}
                  variants={collageVariants}
                >
                  <Image
                    src={'/collage-landingpage/searoses-right-front.png'}
                    alt="searoses"
                    width={300}
                    height={164}
                    layout="responsive"
                    blurDataURL={
                      '/collage-landingpage/searoses-right-front.png'
                    }
                    placeholder="blur"
                  />
                </motion.div>
                {/*
                  Parrot
                */}
                <motion.div
                  className="collage__layer collage--frontpage__layer"
                  id="parrot"
                  animate={isOnTop ? 'visible' : 'hidden'}
                  variants={collageVariants}
                >
                  <Image
                    src={'/collage-landingpage/parrot.png'}
                    alt="parrot"
                    width={200}
                    height={212}
                    layout="responsive"
                    blurDataURL={'/collage-landingpage/parrot.png'}
                    placeholder="blur"
                  />
                </motion.div>
                {/*
                  Boat
                */}
                <motion.div
                  className="collage__layer collage--frontpage__layer"
                  id="boat"
                  animate={isOnTop ? 'visible' : 'hidden'}
                  variants={collageVariants}
                >
                  <Image
                    src={'/collage-landingpage/boat.png'}
                    alt="parrot"
                    width={272}
                    height={401}
                    layout="responsive"
                    blurDataURL={'/collage-landingpage/boat.png'}
                    placeholder="blur"
                  />
                </motion.div>
                {/*
                  Surfer
                */}
                <motion.div
                  className="collage__layer collage--frontpage__layer"
                  id="surfer"
                  animate={isOnTop ? 'visible' : 'hidden'}
                  variants={collageVariants}
                >
                  <Image
                    src={'/collage-landingpage/surfer.png'}
                    alt="surfer"
                    width={200}
                    height={151}
                    layout="responsive"
                    blurDataURL={'/collage-landingpage/surfer.png'}
                    placeholder="blur"
                  />
                </motion.div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>

      <div style={{ backgroundColor: '#ffb2a3' }}>
        <Section hasTop>
          <Container fluid>
            <Row>
              <Col md={6} className={`${'flex flex--align-end'}`}>
                <h1 className={`${'font--xxl'} ${'font--primary'}`}>
                  {RichText.asText(content.headline)}
                </h1>
              </Col>
              <Col
                md={{
                  size: 6,
                  offset: 0,
                }}
                lg={{
                  size: 4,
                  offset: 2,
                }}
              >
                <p>{RichText.asText(content.text)}</p>
                <div className="m-t">
                  <Button title="View amenities" href="/amenities" />
                </div>
              </Col>
            </Row>
          </Container>
        </Section>

        <Section>
          <Container fluid>
            <Row>
              <Col
                md={6}
                // lg={ 4 }
                className="flex flex--align-center"
              >
                <p>{RichText.asText(content.text1)}</p>
              </Col>
            </Row>
          </Container>
        </Section>

        {content.slider.length > 0 && (
          <Slider slides={content.slider} roundedImages size="xl" hasBottom />
        )}

        {/* <Section>
          <Container fluid>
            <Row>
              {content.slider[0].slider__image &&
                content.slider[0].slider__image.url && (
                  <Col
                    md={{
                      size: 8,
                      offset: 0,
                    }}
                    // lg={{ size: 6, offset: 2 }}
                  >
                    <div className="m-t--sm">
                      <div className="container--border-radius container--offset-right">
                        <Image
                          src={content.slider[0].slider__image.url}
                          alt={content.slider[0].slider__image.alt}
                          layout="responsive"
                          blurDataURL={content.slider[0].slider__image.url}
                          placeholder="blur"
                          width={
                            content.slider[0].slider__image.dimensions.width
                          }
                          height={
                            content.slider[0].slider__image.dimensions.height
                          }
                        />
                      </div>
                    </div>
                  </Col>
                )}
            </Row>
          </Container>
        </Section> */}

        <Section>
          <Container fluid>
            <Row>
              <Col
                md={{
                  size: 8,
                  offset: 4,
                }}
              >
                <Row>
                  <Col md={6}>
                    <p>{RichText.asText(content.textfield_left)}</p>
                  </Col>
                  <Col md={6}>
                    <div className="m-t--sm">
                      <p>{RichText.asText(content.textfield_right)}</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Section>
      </div>
    </Layout>
  );
};

export default Home;

export const getStaticProps: GetStaticProps = async () => {
  const res = await Client.query('[at(document.type, "frontpage")]');
  const data = res.results.map((content: { data: any }) => {
    return content.data;
  });
  const content = data[0];

  const gallery = res.results.map((gallery: { data: any }) => {
    const filteredImages = gallery.data;
    return filteredImages;
  });

  return {
    props: {
      content,
      gallery,
    },
  };
};
